var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "邮件确认状态",
            visible: _vm.dialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("i", { staticClass: "iconfont iconduigou1" }),
            _c("div", { staticClass: "right" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v("已收到邮件反馈！"),
              ]),
              _c("span", { staticClass: "result" }, [
                _vm._v("已审阅，稍后确认"),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }