<!--
 * @Author: Libra
 * @Date: 2021-07-06 17:26:34
 * @LastEditTime: 2021-08-03 14:50:05
 * @LastEditors: Libra
 * @Description: 再考虑一下
 * @FilePath: /stone-exam-ui/src/views/invite/Consider.vue
-->
<template>
  <div>
    <el-dialog title="邮件确认状态" :visible.sync="dialogVisible" width="400px">
      <div class="content">
        <i class="iconfont iconduigou1"></i>
        <div class="right">
          <span class="title">已收到邮件反馈！</span>
          <span class="result">已审阅，稍后确认</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .iconfont {
    font-size: 60px;
    color: #cb2a1d;
    margin-right: 20px;
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    .title {
      font-size: 14px;
    }
    .result {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
}
</style>
